@import "../../index.scss";

.button {
  z-index: 100;
  padding: 1rem 4rem;
  border: none;
  color: white;
  font-weight: 700;
  background-color: $secondary;
  // background-color: $grey4;
  border-radius: 0.5rem;
  // border: 1px solid $secondary;
  font-size: 1.6rem;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  height: fit-content;
  //
  // box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.25);

  &-primary {
    background-color: $primary;
    border-radius: 0;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
  }
  &-primary:hover {
    background-color: white;
    color: $primary;
    border-bottom: 3px solid $primary;
    border-right: 3px solid $primary;
  }
  &-curved {
    border-radius: 1rem !important;
  }
  &-curvedSmall {
    border-radius: 3rem !important;
    box-shadow: none !important;
    padding: 0.5rem 2.5rem;
  }
  &-red {
    border: none;
    color: $secondary;
    background-color: rgb(248, 57, 57) !important;
    color: white;
  }
  &-arred {
    border: none;
    color: $secondary;
    border-bottom: 3px solid rgb(255, 102, 102) !important;
    font-size: 1.2rem;
    background-color: white;
  }
  &-argreen {
    border: none;
    color: $secondary;
    border-bottom: 3px solid rgb(60, 128, 60) !important;
    background-color: white;
  }
  &-mini {
    border: none;
    color: $secondary;
    box-shadow: none;
    font-size: 1.2rem;
    padding-top: 0.5rem;
    padding: 0 0;
    border-radius: 0.2rem;
    border-bottom: 1px solid rgb(60, 128, 60) !important;
    background-color: transparent;
  }
  &-minired {
    border: none;
    color: $danger;
    box-shadow: none;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding: 0 0;
    border-radius: 0.2rem;
    border-bottom: 1px solid $danger !important;
    background-color: transparent;
  }
  &-minigreen {
    border: none;
    color: $success;
    box-shadow: none;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding: 0 0;
    border-radius: 0.2rem;
    border-bottom: 1px solid $success !important;
    background-color: transparent;
  }
  &-arrow {
    border: none;
    color: $secondary;
    border-bottom: 3px solid $secondary !important;
    background-color: white;

    & .arrow {
      margin-left: 1rem;
    }
  }
}
// .button:hover {
//   border: none;
//   color: $secondary;
//   background-color: white;
// }
