.expenses-bottom-upload > button {
  margin-left: 20px;
}

// Variables
$primary-color: #4f46e5;
$primary-light: #eef2ff;
$primary-dark: #4338ca;
$secondary-color: #f43f5e;
$secondary-light: #ffe4e6;
$danger-color: #ef4444;
$warning-color: #f59e0b;
$success-color: #10b981;
$success-light: #d1fae5;
$background-color: #f9fafb;
$card-bg: #ffffff;
$text-primary: #1f2937;
$text-secondary: #6b7280;
$text-light: #9ca3af;
$border-color: #e5e7eb;
$table-header-bg: #2e2c73;
$table-header-text: #ffffff;
$border-radius: 12px;
$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$transition: all 0.2s ease;

// Mixins
@mixin card {
  background: $card-bg;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  transition: $transition;
}

// Main Container
.expense-dashboard {
  display: flex;
  flex-direction: column;
  background-color: $background-color;
  min-height: 100vh;
  padding: 24px;
  gap: 24px;

  // Dashboard Header with integrated metrics
  .dashboard-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;

    .dashboard-title {
      font-size: 28px;
      font-weight: 700;
      color: $text-primary;
      margin: 0;
    }

    .dashboard-metrics-container {
      display: flex;
      align-items: center;
      gap: 16px;

      // Compact metrics design
      .compact-metric {
        display: flex;
        flex-direction: column;
        padding: 8px 12px;
        border-radius: 8px;
        background-color: $card-bg;
        border-left: 3px solid;
        min-width: 160px;
        box-shadow: $box-shadow;

        &.expense {
          border-left-color: $secondary-color;

          .metric-icon {
            color: $secondary-color;
          }
        }

        .metric-label {
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: 13px;
          font-weight: 500;
          color: $text-secondary;
          margin-bottom: 4px;

          .metric-icon {
            width: 14px;
            height: 14px;
          }
        }

        .metric-value {
          font-size: 20px;
          font-weight: 600;
          color: $text-primary;
        }
      }

      // Export button
      .export-button {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: $primary-color;
        color: white;
        padding: 10px 16px;
        height: 44px;
        border-radius: 8px;
        border: none;
        font-weight: 600;
        font-size: 13px;
        cursor: pointer;
        transition: $transition;
        box-shadow: 0 4px 6px rgba($primary-color, 0.25);

        .button-icon {
          width: 16px;
          height: 16px;
        }

        &:hover {
          background-color: $primary-dark;
          transform: translateY(-2px);
        }
      }
    }

    // Responsive adjustments
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;

      .dashboard-metrics-container {
        width: 100%;
        overflow-x: auto;
        padding-bottom: 8px;
      }
    }
  }

  // Filters Container
  .filters-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px 20px;
    @include card;

    .filter-group {
      display: flex;
      flex-direction: column;
      min-width: 180px;
      flex: 1;

      .filter-label {
        font-size: 13px;
        font-weight: 500;
        color: $text-secondary;
        margin-bottom: 6px;
      }

      .date-picker,
      .filter-dropdown {
        width: 100%;

        :global(.rs-picker-toggle) {
          border-radius: 8px;
          border-color: $border-color;

          &:hover,
          &:focus {
            border-color: $primary-color;
          }
        }
      }
    }
  }

  // Content Container
  .content-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    flex: 1;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
    }

    .section-title {
      font-size: 14px;
      font-weight: 600;
      color: $text-primary;
      margin: 0;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .header-actions {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      .search-container {
        position: relative;

        .search-icon {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: $text-light;
          width: 16px;
          height: 16px;
        }

        .search-input {
          padding: 8px 8px 8px 32px;
          border: 1px solid $border-color;
          border-radius: 6px;
          font-size: 14px;

          &:focus {
            outline: none;
            border-color: $primary-color;
          }
        }
      }

      .add-button {
        display: flex;
        align-items: center;
        gap: 6px;
        background-color: $success-color;
        color: white;
        padding: 8px 12px;
        border-radius: 6px;
        border: none;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        transition: $transition;

        .button-icon {
          width: 14px;
          height: 14px;
        }

        &:hover {
          background-color: darken($success-color, 5%);
        }
      }
    }

    // Expense List
    .expense-list-container {
      @include card;
      padding: 20px;
      display: flex;
      flex-direction: column;

      .expense-list {
        flex: 1;
        overflow-y: auto;

        // Styling for HalfList
        :global(.half-list-item) {
          padding: 12px 16px;
          border-bottom: 1px solid $border-color;
          cursor: pointer;
          transition: $transition;

          &:hover {
            background-color: $primary-light;
          }

          &.selected {
            background-color: $primary-light;
            border-left: 3px solid $primary-color;
          }
        }

        .error-message {
          color: $danger-color;
          margin-top: 16px;
          font-size: 14px;
        }
      }
    }

    // Expense Details
    .expense-details-container {
      @include card;
      padding: 20px;
      display: flex;
      flex-direction: column;

      .details-card {
        flex: 1;

        // Styling for HalfDetails
        :global(.details-header) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 16px;
          border-bottom: 1px solid $border-color;
          margin-bottom: 16px;

          h3 {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
          }

          .details-actions {
            display: flex;
            gap: 8px;

            button {
              padding: 6px 12px;
              border-radius: 6px;
              font-size: 13px;
              font-weight: 500;
              cursor: pointer;
              transition: $transition;
              display: flex;
              align-items: center;
              gap: 6px;

              &.edit-button {
                background-color: $primary-light;
                color: $primary-color;
                border: 1px solid $primary-color;

                &:hover {
                  background-color: $primary-color;
                  color: white;
                }
              }

              &.delete-button {
                background-color: $secondary-light;
                color: $secondary-color;
                border: 1px solid $secondary-color;

                &:hover {
                  background-color: $secondary-color;
                  color: white;
                }
              }
            }
          }
        }
      }

      .no-selection-message {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $text-secondary;
        gap: 16px;
        padding: 40px;

        .empty-state-icon {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: $primary-light;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 24px;
            height: 24px;
            color: $primary-color;
          }
        }

        p {
          font-size: 14px;
          text-align: center;
          margin: 0;
        }
      }
    }
  }

  // Permission Denied
  .permission-denied {
    @include card;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    svg {
      width: 48px;
      height: 48px;
      color: $warning-color;
    }

    p {
      font-size: 16px;
      color: $text-secondary;
      text-align: center;
      margin: 0;
    }
  }
}

// Table styling (assuming HalfList uses a table-like structure)
.expense-list-container table {
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: $table-header-bg;
    color: $table-header-text;

    th {
      padding: 12px 16px;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
    }
  }

  tbody tr {
    border-bottom: 1px solid $border-color;
    transition: $transition;

    &:hover {
      background-color: $primary-light;
    }

    td {
      padding: 12px 16px;
    }
  }
}

// Modal Styling
:global(.rs-modal) {
  .rs-modal-content {
    border-radius: $border-radius;
    overflow: hidden;

    .rs-modal-header {
      padding: 16px 20px;
      background-color: $primary-light;

      .rs-modal-title {
        font-weight: 600;
        color: $text-primary;
      }
    }

    .rs-modal-body {
      padding: 20px;
    }
  }
}

// Custom status badges
.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;

  &.pending {
    background-color: $warning-color;
    color: white;
  }

  &.done {
    background-color: $success-color;
    color: white;
  }
}
