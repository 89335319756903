@import "../../index.scss";
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(3px);

  .loader-content {
    display: flex;
    align-items: flex-end;
    height: 40px;
    gap: 6px;
  }

  .loader-bar {
    width: 8px;
    height: 12px;
    background-color: $primary;
    border-radius: 10px;
    transform-origin: bottom;
    animation: loader-animation 1.2s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      animation-delay: 0.8s;
    }
  }
}

@keyframes loader-animation {
  0%,
  100% {
    height: 12px;
  }

  50% {
    height: 40px;
  }
}
