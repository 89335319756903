// Enhanced Resources.scss

.resources-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background-color: #f8fafc;
  min-height: calc(100vh - 70px); // Subtracting header height

  // Header section
  .resources-header {
    margin-bottom: 24px;

    .resources-title {
      font-size: 28px;
      font-weight: 700;
      color: #1e293b;
      margin: 0 0 8px 0;
    }

    .resources-subtitle {
      font-size: 16px;
      color: #64748b;
      margin: 0;
    }
  }

  // Toolbar with search and filters
  .resources-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 24px;

    .search-bar {
      position: relative;
      width: 100%;
      max-width: 400px;

      .search-icon {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2394a3b8'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        pointer-events: none;
      }

      input {
        width: 100%;
        padding: 12px 40px 12px 40px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        font-size: 15px;
        background-color: white;
        transition: all 0.2s ease;

        &:focus {
          outline: none;
          border-color: #3b82f6;
          box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
        }

        &::placeholder {
          color: #94a3b8;
        }
      }

      .clear-search {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        font-size: 18px;
        color: #94a3b8;
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 50%;
        transition: all 0.2s ease;

        &:hover {
          background-color: #f1f5f9;
          color: #475569;
        }
      }
    }

    .filters {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;

      .filter-group {
        display: flex;
        align-items: center;
        gap: 8px;

        label {
          font-size: 14px;
          color: #64748b;
          font-weight: 500;
        }

        select {
          padding: 8px 32px 8px 12px;
          border: 1px solid #e2e8f0;
          border-radius: 6px;
          background-color: white;
          font-size: 14px;
          color: #1e293b;
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2394a3b8'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 8px center;
          background-size: 16px;
          transition: all 0.2s ease;
          cursor: pointer;

          &:focus {
            outline: none;
            border-color: #3b82f6;
            box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
          }
        }
      }
    }
  }

  // Main content area
  .resources-content {
    flex: 1;

    // Loading state
    .resources-loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 48px 24px;

      .loading-spinner {
        width: 40px;
        height: 40px;
        border: 3px solid rgba(59, 130, 246, 0.2);
        border-top-color: #3b82f6;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        margin-bottom: 16px;
      }

      p {
        color: #64748b;
        font-size: 16px;
      }

      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    }

    // Error state
    .resources-error {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 48px 24px;

      .error-icon {
        width: 48px;
        height: 48px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23ef4444'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 16px;
      }

      p {
        color: #ef4444;
        margin: 0 0 16px;
        font-size: 16px;
      }

      .retry-button {
        padding: 10px 20px;
        background-color: transparent;
        border: 1px solid #ef4444;
        color: #ef4444;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background-color: #ef4444;
          color: white;
        }
      }
    }

    // Empty state
    .resources-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 48px 24px;

      .empty-icon {
        width: 48px;
        height: 48px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2394a3b8'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 16px;
        opacity: 0.6;
      }

      p {
        color: #64748b;
        margin: 0 0 16px;
        font-size: 16px;
      }

      .clear-search-button {
        padding: 10px 20px;
        background-color: transparent;
        border: 1px solid #64748b;
        color: #64748b;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background-color: #f1f5f9;
          border-color: #475569;
          color: #475569;
        }
      }
    }

    // Resource grid
    .resources-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 16px;

      .resource-card {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        transition: transform 0.2s ease, box-shadow 0.2s ease;
        overflow: hidden;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        .resource-icon {
          height: 120px;
          background-color: #f1f5f9;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 48px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.pdf-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ef4444'%3E%3Cpath d='M8 2h8a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V7.414A2 2 0 014.586 6L7.586 3A2 2 0 019 2.586V2z'%3E%3C/path%3E%3C/svg%3E");
          }

          &.word-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%232563eb'%3E%3Cpath d='M8 2h8a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V7.414A2 2 0 014.586 6L7.586 3A2 2 0 019 2.586V2z'%3E%3C/path%3E%3C/svg%3E");
          }

          &.excel-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2322c55e'%3E%3Cpath d='M8 2h8a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V7.414A2 2 0 014.586 6L7.586 3A2 2 0 019 2.586V2z'%3E%3C/path%3E%3C/svg%3E");
          }

          &.powerpoint-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23f97316'%3E%3Cpath d='M8 2h8a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V7.414A2 2 0 014.586 6L7.586 3A2 2 0 019 2.586V2z'%3E%3C/path%3E%3C/svg%3E");
          }

          &.image-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%238b5cf6'%3E%3Cpath d='M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z'%3E%3C/path%3E%3C/svg%3E");
          }

          &.text-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2364748b'%3E%3Cpath d='M8 2h8a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V7.414A2 2 0 014.586 6L7.586 3A2 2 0 019 2.586V2z'%3E%3C/path%3E%3C/svg%3E");
          }

          &.archive-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a16207'%3E%3Cpath d='M5 3a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h14v14H5V5zm2 2v2h10V7H7zm0 4v2h10v-2H7zm0 4v2h7v-2H7z'%3E%3C/path%3E%3C/svg%3E");
          }

          &.generic-file-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2364748b'%3E%3Cpath d='M8 2h8a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V7.414A2 2 0 014.586 6L7.586 3A2 2 0 019 2.586V2z'%3E%3C/path%3E%3C/svg%3E");
          }
        }

        .resource-details {
          padding: 16px;
          flex: 1;

          .resource-name {
            font-size: 16px;
            font-weight: 600;
            color: #1e293b;
            margin: 0 0 12px 0;
            line-height: 1.4;
            // For long filenames
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .resource-meta {
            display: flex;
            justify-content: space-between;
            color: #64748b;
            font-size: 13px;

            .resource-size {
              display: flex;
              align-items: center;

              &:before {
                content: "";
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-right: 4px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2364748b'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 10v6m0 0l-3-3m3 3l3-3M3 17V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'%3E%3C/path%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
              }
            }

            .resource-date {
              display: flex;
              align-items: center;

              &:before {
                content: "";
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-right: 4px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2364748b'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'%3E%3C/path%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }

        .download-button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 12px 16px;
          background-color: #f8fafc;
          border-top: 1px solid #e2e8f0;
          color: #3b82f6;
          text-decoration: none;
          font-size: 14px;
          font-weight: 600;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #eff6ff;
          }

          .download-icon {
            width: 16px;
            height: 16px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%233b82f6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4'%3E%3C/path%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }

          .download-text {
            // For very small screens
            @media (max-width: 350px) {
              display: none;
            }
          }
        }
      }
    }
  }

  // Footer section
  .resources-footer {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid #e2e8f0;

    p {
      font-size: 14px;
      color: #64748b;
      margin: 0;
      text-align: center;
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .resources-container {
    padding: 16px;

    .resources-header {
      .resources-title {
        font-size: 24px;
      }

      .resources-subtitle {
        font-size: 14px;
      }
    }

    .resources-toolbar {
      flex-direction: column;

      .search-bar {
        max-width: none;
      }

      .filters {
        width: 100%;
        justify-content: space-between;

        .filter-group {
          flex: 1;

          select {
            width: 100%;
          }
        }
      }
    }

    .resources-content {
      .resources-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

        .resource-card {
          .resource-icon {
            height: 100px;
          }

          .resource-details {
            padding: 12px;

            .resource-name {
              font-size: 14px;
              margin-bottom: 8px;
            }

            .resource-meta {
              font-size: 12px;
            }
          }

          .download-button {
            padding: 10px 12px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .resources-container {
    .resources-content {
      .resources-grid {
        grid-template-columns: 1fr;
      }
    }

    .filters {
      .filter-group {
        label {
          font-size: 12px;
        }

        select {
          font-size: 13px;
          padding: 8px 24px 8px 8px;
        }
      }
    }
  }
}
