// Enhanced AnnouncementsDisplay.scss

.announcements-display {
  width: 100%;

  .announcement-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    padding: 16px;
    margin-bottom: 16px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border-left: 4px solid #3498db;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &:last-child {
      margin-bottom: 0;
    }

    .announcement-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 12px;
      flex-wrap: wrap;
      gap: 8px;

      .announcement-title {
        font-size: 16px;
        font-weight: 600;
        color: #2c3e50;
        margin: 0;
        flex: 1;
      }

      .announcement-date {
        font-size: 12px;
        color: #7f8c8d;
        white-space: nowrap;
      }
    }

    .announcement-content {
      color: #4a5568;
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }

  .no-announcements {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 24px;
    text-align: center;
    color: #6c757d;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      margin-bottom: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236c757d' stroke-width='1.5'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

// Animation for new announcements
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.new-announcement {
  animation: fadeIn 0.3s ease-out;
}

// Different color variants for announcement types
.announcement-card {
  &.everyone {
    border-left-color: #3498db;
  }

  &.students {
    border-left-color: #2ecc71;
  }

  &.staff {
    border-left-color: #f39c12;
  }

  &.urgent {
    border-left-color: #e74c3c;

    .announcement-title {
      display: flex;
      align-items: center;

      &:before {
        content: "!";
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        background-color: #e74c3c;
        color: white;
        border-radius: 50%;
        margin-right: 8px;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}

// Responsive styles
@media (max-width: 576px) {
  .announcements-display {
    .announcement-card {
      padding: 12px;

      .announcement-header {
        flex-direction: column;
        align-items: flex-start;

        .announcement-date {
          margin-top: 4px;
        }
      }
    }
  }
}
