// ExpenseForm.scss
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Playfair+Display:wght@700&display=swap");

// Variables
$primary-color: #4361ee;
$primary-dark: #3a56d4;
$primary-light: #edf2ff;
$secondary-color: #64748b;
$accent-color: #0ea5e9;
$success-color: #10b981;
$warning-color: #f59e0b;
$danger-color: #ef4444;
$text-dark: #1e293b;
$text-light: #64748b;
$text-muted: #94a3b8;
$border-light: #e2e8f0;
$border-dark: #cbd5e1;
$bg-light: #f8fafc;
$bg-white: #ffffff;
$bg-hover: #f1f5f9;
$shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1);
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
$radius-sm: 0.4rem;
$radius-md: 0.6rem;
$radius-lg: 0.8rem;
$font-primary: "Lato", sans-serif;
$font-title: "Playfair Display", serif;

// Base styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Container
.expense-form-container {
  width: 100%;
  max-width: 120rem;
  margin: 3rem auto;
  padding: 0 2rem;
  font-family: $font-primary;
  color: $text-dark;
}

// Action Bar
.action-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;

  .action-button {
    display: flex;
    align-items: center;
    padding: 1rem 1.6rem;
    background-color: $bg-white;
    border: 0.1rem solid $border-dark;
    border-radius: $radius-sm;
    color: $text-dark;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: $shadow-sm;

    svg {
      margin-right: 0.8rem;
      font-size: 1.6rem;
      color: $accent-color;
    }

    &:hover {
      background-color: $bg-hover;
    }
  }
}

// Form
.expense-form {
  background-color: $bg-white;
  border-radius: $radius-lg;
  box-shadow: $shadow-lg;
  position: relative;
  overflow: hidden;

  // Form Header
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.5rem;
    background: linear-gradient(135deg, $primary-light 0%, white 100%);
    border-bottom: 0.1rem solid $border-light;

    .company-branding {
      display: flex;
      align-items: center;

      .logo-area {
        margin-right: 2rem;

        .company-logo {
          //   font-size: 4rem;
          color: $primary-color;
          background-color: white;
          padding: 1rem;
          border-radius: 50%;
          box-shadow: $shadow-md;

          width: 9rem;
          height: auto;
        }
      }

      .company-info {
        h1 {
          font-family: $font-title;
          font-size: 2.8rem;
          font-weight: 700;
          color: $text-dark;
          margin-bottom: 0.6rem;
        }

        .gstin {
          font-size: 1.6rem;
          color: $text-light;
        }
      }
    }

    .invoice-title {
      .invoice-label {
        display: inline-block;
        font-size: 1.8rem;
        font-weight: 700;
        color: $primary-color;
        padding: 1rem 2rem;
        background-color: white;
        border-radius: $radius-md;
        box-shadow: $shadow-md;
        letter-spacing: 0.1rem;
        transform: rotate(2deg);
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: -0.5rem;
          right: 1rem;
          width: 70%;
          height: 0.5rem;
          background-color: rgba($primary-color, 0.1);
          border-radius: 0.5rem;
        }
      }
    }
  }

  // Form Content
  .form-content {
    padding: 3.5rem;
  }

  // Invoice Details Section
  .invoice-details-section {
    margin-bottom: 4rem;
  }

  // Invoice Sections
  .invoice-sections {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%239C92AC' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
      opacity: 0.5;
      pointer-events: none;
    }
  }

  // Section Group
  .section-group {
    margin-bottom: 4rem;
    position: relative;
    z-index: 1;
  }

  // Section Split
  .section-split {
    display: flex;
    gap: 4rem;

    .section-column {
      flex: 1;
      min-width: 25rem;
    }
  }

  // Section Titles
  .section-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: $text-dark;
    margin-bottom: 2rem;
    position: relative;
    padding-bottom: 1rem;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 6rem;
      height: 0.3rem;
      background-color: $primary-color;
      border-radius: 0.3rem;
    }
  }

  // Form Rows
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.two-columns {
      .form-field {
        flex: 1;
        min-width: 25rem;
      }
    }

    &.three-columns {
      .form-field {
        flex: 1;
        min-width: 20rem;
      }
    }
  }

  // Form Fields
  .form-field {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      display: block;
      margin-bottom: 0.8rem;
      font-size: 1.4rem;
      font-weight: 500;
      color: $text-light;
    }

    // Underlined input
    .underlined-input {
      border: none;
      border-bottom: 0.2rem solid $border-dark;
      border-radius: 0;
      padding: 1rem 0;
      font-size: 1.6rem;
      background-color: transparent;
      width: 100%;

      &:focus {
        outline: none;
        border-bottom-color: $primary-color;
      }

      &::placeholder {
        color: $text-muted;
      }
    }

    // Date input
    .date-input-wrapper {
      position: relative;

      input[type="date"] {
        width: 100%;
        padding: 1rem 4rem 1rem 1.5rem;
        border: 0.1rem solid $border-dark;
        border-radius: $radius-sm;
        font-size: 1.6rem;
        background-color: $bg-white;

        &:focus {
          outline: none;
          border-color: $primary-color;
        }
      }

      .date-icon {
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        color: $text-light;
        font-size: 1.6rem;
        pointer-events: none;
      }
    }

    // Checkbox
    .checkbox-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      input[type="checkbox"] {
        width: 1.8rem;
        height: 1.8rem;
        margin: 0;
        accent-color: $primary-color;
        cursor: pointer;
      }

      label {
        margin-bottom: 0;
        cursor: pointer;
        font-size: 1.6rem;
      }
    }

    // GST Rate
    .gst-rate-wrapper {
      margin-top: 1.5rem;
    }

    // Bordered select
    .bordered-select {
      width: 100%;
      padding: 1rem 1.5rem;
      border: 0.1rem solid $border-dark;
      border-radius: $radius-sm;
      font-size: 1.6rem;
      background-color: $bg-white;
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2364748b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1.5rem center;
      background-size: 1.6rem;
      padding-right: 4rem;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }

    // Textarea
    textarea {
      width: 100%;
      padding: 1.5rem;
      border: 0.1rem solid $border-dark;
      border-radius: $radius-sm;
      resize: vertical;
      font-size: 1.6rem;
      min-height: 12rem;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }

      &::placeholder {
        color: $text-muted;
      }
    }
  }

  // Selected Vendor
  .selected-vendor {
    margin: 1.5rem 0;
    padding: 1.5rem;
    background-color: $bg-light;
    border-left: 0.3rem solid $primary-color;
    border-radius: $radius-sm;

    .vendor-name {
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 0.8rem;
      color: $text-dark;
    }

    .vendor-address {
      font-size: 1.4rem;
      color: $text-light;
    }
  }

  // New Vendor Button
  .btn-new-vendor {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    margin-top: 1.5rem;
    padding: 1rem 1.6rem;
    background-color: $bg-white;
    border: 0.1rem solid $primary-color;
    border-radius: $radius-sm;
    color: $primary-color;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: $primary-light;
    }
  }

  // Items Section
  .items-section {
    margin-bottom: 4rem;
    background-color: $bg-white;
    border-radius: $radius-md;
    padding: 2.5rem;
    box-shadow: $shadow-sm;
    position: relative;
    z-index: 1;
  }

  // Items Table
  .items-table {
    border: 0.1rem solid $border-dark;
    border-radius: $radius-sm;
    overflow: hidden;
    margin-bottom: 2.5rem;

    .items-header {
      display: flex;
      background-color: $primary-light;
      padding: 1.2rem 1.5rem;
      font-weight: 600;
      font-size: 1.4rem;
      color: $primary-color;
    }

    .col {
      &.description {
        flex: 3;
      }
      &.quantity {
        flex: 1;
        text-align: center;
      }
      &.price {
        flex: 1;
        text-align: center;
      }
      &.amount {
        flex: 1;
        text-align: center;
      }
      &.actions {
        width: 5rem;
        text-align: center;
      }
    }

    .items-body {
      .item-row {
        display: flex;
        border-bottom: 0.1rem solid $border-light;

        &:last-child {
          border-bottom: none;
        }

        &:nth-child(even) {
          background-color: $bg-light;
        }

        .col {
          padding: 1.2rem 1.5rem;
          display: flex;
          align-items: center;

          input {
            width: 100%;
            padding: 0.8rem 1rem;
            border: 0.1rem solid transparent;
            border-radius: $radius-sm;
            font-size: 1.4rem;
            background-color: transparent;

            &:focus {
              border-color: $border-dark;
              outline: none;
              background-color: white;
            }
          }

          &.amount {
            justify-content: center;
            font-weight: 500;
            font-size: 1.4rem;
          }

          &.actions {
            justify-content: center;
          }
        }
      }
    }

    .add-item-row {
      padding: 1.5rem;
      display: flex;
      justify-content: center;
      border-top: 0.1rem solid $border-light;
    }

    .btn-add-item {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      padding: 1rem 1.6rem;
      background-color: $bg-white;
      border: 0.1rem dashed $primary-color;
      border-radius: $radius-sm;
      color: $primary-color;
      font-size: 1.4rem;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: $primary-light;
      }
    }

    .btn-remove-item {
      background-color: transparent;
      border: none;
      color: $text-light;
      cursor: pointer;
      width: 2.8rem;
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        color: $danger-color;
        background-color: rgba($danger-color, 0.1);
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }

  // Invoice Totals
  .invoice-totals {
    width: 100%;
    max-width: 35rem;
    margin-left: auto;
    margin-top: 2rem;

    .summary-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 2rem;
      font-size: 1.6rem;
      border-bottom: 0.1rem solid $border-light;

      &:last-child {
        border-bottom: none;
      }

      &.total {
        font-weight: 700;
        font-size: 1.8rem;
        color: $primary-color;
        background-color: $primary-light;
        border-radius: $radius-sm;
        margin-top: 1rem;
      }
    }
  }

  // Additional Details Section
  .additional-details-section {
    margin-bottom: 4rem;
    position: relative;
    z-index: 1;
  }

  // File Upload
  .file-upload-area {
    border: 0.2rem dashed $border-light;
    border-radius: $radius-sm;
    padding: 2.5rem;
    text-align: center;
    transition: all 0.2s;
    margin-bottom: 1.5rem;

    &:hover {
      border-color: $primary-color;
      background-color: $primary-light;
    }

    .file-upload-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      cursor: pointer;

      svg {
        font-size: 3rem;
        color: $primary-color;
      }

      span {
        font-weight: 500;
        font-size: 1.5rem;
        color: $text-light;
      }
    }
  }

  // Uploaded Files
  .uploaded-files {
    .file-list {
      list-style: none;

      .file-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.2rem 1.5rem;
        background-color: $bg-light;
        border-left: 0.3rem solid $accent-color;
        border-radius: $radius-sm;
        margin-bottom: 1rem;

        .file-name {
          font-size: 1.4rem;
          color: $text-dark;
        }

        .btn-remove-file {
          background-color: transparent;
          border: none;
          color: $text-light;
          cursor: pointer;

          &:hover {
            color: $danger-color;
          }
        }
      }
    }
  }

  // Form Actions
  .form-actions {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: -2.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      height: 0.1rem;
      background: linear-gradient(
        to right,
        transparent,
        $border-dark,
        transparent
      );
    }

    .btn-submit {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1.2rem 3rem;
      background-color: $primary-color;
      border: none;
      border-radius: $radius-sm;
      color: white;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s;
      box-shadow: $shadow-md;

      &:hover {
        background-color: $primary-dark;
        transform: translateY(-0.2rem);
        box-shadow: $shadow-lg;
      }
    }
  }

  // Form Footer
  .form-footer {
    padding: 2.5rem;
    background-color: $bg-light;
    border-top: 0.1rem solid $border-light;
    text-align: center;

    .footer-content {
      .company-name {
        font-weight: 700;
        font-size: 1.6rem;
        margin-bottom: 0.8rem;
        color: $text-dark;
      }

      .company-details {
        font-size: 1.4rem;
        color: $text-light;
        margin-bottom: 1rem;
      }

      .footer-note {
        font-style: italic;
        color: $primary-color;
        font-size: 1.5rem;
      }
    }
  }
}

// Vendor Modal
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  width: 100%;
  max-width: 60rem;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modal-close-btn {
  position: absolute;
  top: 2rem;
  right: 3rem;
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: $text-dark;
  font-weight: 500;
}

.vendor-modal-content {
  h2 {
    font-size: 2.6rem;
    font-weight: 700;
    color: $text-dark;
    margin-bottom: 3rem;
    text-align: center;
    position: relative;
    padding-bottom: 1.5rem;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 6rem;
      height: 0.3rem;
      background-color: $primary-color;
      border-radius: 0.3rem;
    }
  }

  .vendor-form {
    display: grid;
    grid-template-columns: minmax(15rem, auto) minmax(25rem, 1fr);
    gap: 2rem;
    align-items: center;

    label {
      font-size: 1.8rem;
      font-weight: 500;
      color: $text-dark;
      text-align: right;
      padding-right: 1rem;
    }

    input,
    textarea {
      width: 100%;
      font-size: 1.6rem;
      padding: 1.2rem 1.6rem;
      border: 0.1rem solid $border-dark;
      border-radius: $radius-sm;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }

      &::placeholder {
        color: $text-muted;
      }
    }

    textarea {
      resize: vertical;
      min-height: 10rem;
    }

    .modal-actions {
      grid-column: 1 / -1;
      display: flex;
      justify-content: center;
      gap: 1.5rem;
      margin-top: 3rem;

      .btn-cancel {
        padding: 1.2rem 2.5rem;
        background-color: $bg-white;
        border: 0.1rem solid $border-dark;
        border-radius: $radius-sm;
        color: $text-dark;
        font-size: 1.6rem;
        font-weight: 500;
        cursor: pointer;
        width: 20rem;
        transition: all 0.2s;

        &:hover {
          background-color: $bg-light;
        }
      }

      .btn-save {
        padding: 1.2rem 2.5rem;
        background-color: $primary-color;
        border: none;
        border-radius: $radius-sm;
        color: white;
        font-size: 1.6rem;
        font-weight: 500;
        cursor: pointer;
        width: 20rem;
        transition: all 0.2s;

        &:hover {
          background-color: $primary-dark;
        }
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .expense-form {
    .form-header {
      flex-direction: column;
      gap: 2rem;

      .company-branding {
        width: 100%;
      }

      .invoice-title {
        align-self: flex-start;
      }
    }

    .section-split {
      flex-direction: column;
      gap: 3rem;
    }

    .items-table {
      .items-header {
        display: none;
      }

      .items-body {
        .item-row {
          flex-direction: column;
          padding: 1.5rem;

          .col {
            width: 100%;
            padding: 0.8rem 0;

            &::before {
              content: attr(class);
              display: block;
              text-transform: capitalize;
              font-weight: 500;
              margin-bottom: 0.5rem;
              font-size: 1.2rem;
              color: $text-light;
            }

            &.actions {
              align-self: flex-end;
            }
          }
        }
      }
    }

    .invoice-totals {
      max-width: 100%;
    }
  }
}
