// Enhanced HalfList.scss
@import "../../index.scss";
.half-list {
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 100%;
  border: 2px solid $primary;
  border-radius: 1rem;

  // Header section
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eaedf0;
    background-color: $primary;

    .search-container {
      flex: 1;

      .search-input-wrapper {
        position: relative;
        width: 100%;
        max-width: 300px;

        .search-input {
          width: 100%;
          padding: 10px 36px 10px 36px;
          border: 1px solid #e0e4e8;
          border-radius: 6px;
          font-size: 14px;
          color: #333;
          transition: all 0.2s ease;
          background-color: #f9fafb;

          &:focus {
            outline: none;
            border-color: #3498db;
            box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
            background-color: #fff;
          }

          &::placeholder {
            color: #a0aec0;
          }
        }

        .search-icon {
          position: absolute;
          left: 12px;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23a0aec0'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          pointer-events: none;
        }

        .clear-search {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          background: none;
          border: none;
          color: #a0aec0;
          font-size: 16px;
          cursor: pointer;
          padding: 4px;
          line-height: 1;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #e2e8f0;
            color: #4a5568;
          }
        }
      }
    }

    .add-button-container {
      margin-left: 16px;

      button {
        background-color: #ffffff;
        color: $primary;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #2980b9;
        }
      }
    }
  }

  // List container
  .list-container {
    flex: 1;
    overflow: auto;
    position: relative;

    .data-table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 12px 16px;
        text-align: left;
        vertical-align: middle;
        border-bottom: 1px solid #edf2f7;
        font-size: 14px;
      }

      th {
        background-color: #f8fafc;
        font-weight: 600;
        color: #2d3748;
        position: sticky;
        top: 0;
        z-index: 10;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #edf2f7;
        }

        &.sorted-ascending,
        &.sorted-descending {
          background-color: #ebf8ff;
          color: #3498db;
        }

        .sort-indicator {
          display: inline-block;
          margin-left: 4px;
          color: #3498db;
        }
      }

      tbody tr {
        transition: background-color 0.2s ease;
        cursor: pointer;

        &:hover {
          background-color: #f7fafc;
        }

        &.selected-row {
          background-color: #ebf8ff;
          font-weight: 500;

          td {
            border-bottom-color: #bee3f8;
          }
        }

        td {
          color: #4a5568;
        }
      }
    }

    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 20px;
      color: #718096;
      text-align: center;

      .no-data-icon {
        width: 48px;
        height: 48px;
        margin-bottom: 16px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23a0aec0'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.6;
      }

      p {
        margin: 0 0 16px;
        font-size: 15px;
      }

      .reset-search {
        background-color: transparent;
        border: 1px solid #a0aec0;
        color: #4a5568;
        border-radius: 4px;
        padding: 8px 16px;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background-color: #f7fafc;
          border-color: #718096;
        }
      }
    }
  }

  // Footer section
  &-footer {
    padding: 12px 16px;
    border-top: 1px solid #eaedf0;
    font-size: 13px;
    color: #718096;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8fafc;

    .records-count {
      font-weight: 500;
    }

    .search-info {
      font-style: italic;
      font-size: 12px;
    }
  }
}

// Responsive adjustments
@media screen and (max-width: 768px) {
  .half-list {
    &-header {
      flex-direction: column;
      align-items: stretch;
      gap: 12px;

      .search-container {
        .search-input-wrapper {
          max-width: none;
        }
      }

      .add-button-container {
        margin-left: 0;
        display: flex;
        justify-content: flex-end;
      }
    }

    .list-container {
      .data-table {
        th,
        td {
          padding: 10px 12px;
          font-size: 13px;
        }
      }
    }

    &-footer {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
}

// @import "../../index.scss";

// .halflist {
//   align-items: start;
//   max-height: 70vh;
//   overflow-y: scroll;
//   border-radius: 2rem;

//   // background-color: #4585e5c1;
//   background-color: $secondary;

//   &-header {
//     width: 100%;
//     padding: 1rem;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     // background-color: $grey4;

//     // &-count {
//     //   color: white;
//     //   font-weight: 700;
//     //   font-size: 18px;
//     // }

//     &-search {
//       width: 60%;

//       & input {
//         width: 100%;
//         padding: 0.4rem 1rem;

//         border-radius: 30px;
//       }
//     }

//     // & button {
//     //   flex: 3fr;
//     //   // width: 30%;
//     //   margin-left: 0.5rem;
//     // }
//   }

//   &-content {
//     flex: 1;

//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//     background-color: white;
//   }

//   &-footer {
//     padding: 1rem;
//     text-align: left;
//     // background-color: $grey4;
//     background-color: $primary;
//     font-weight: 700;
//     color: white;
//   }

//   .list-section,
//   .details-section {
//     flex: 1;

//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//     background-color: white;
//   }
//   table {
//     width: 100%;
//     border-collapse: collapse;
//     text-align: left;
//     position: relative;

//     thead {
//       // position: static;
//       tr {
//         background-color: $primary;
//         color: white;

//         th {
//           padding: 10px 15px;
//           border-bottom: 2px solid #ccc;
//         }
//       }
//     }
//   }

//   .list-section {
//     overflow-x: auto;
//     height: 50vh;
//     margin: 0 1rem;
//     padding-bottom: 3rem;

//     input[type="text"] {
//       width: 100%;
//       padding: 10px;
//       margin-bottom: 10px;
//       border: 1px solid #ccc;
//       border-radius: 4px;
//       box-sizing: border-box;
//     }

//     table {
//       width: 100%;
//       border-collapse: collapse;
//       text-align: left;
//       position: relative;
//       border-collapse: separate;

//       thead {
//         // width: 80%;
//         // position: static;

//         // padding: 0 1rem;

//         tr {
//           background-color: white;
//           margin: 0 1rem;
//           color: $primary;
//           border-radius: 2rem;
//           border: 2px solid red;
//           margin-bottom: 1rem;

//           th {
//             padding: 10px 15px;
//             border-bottom: 2px solid #ccc;
//             border-radius: 2rem;
//           }
//         }
//       }
//       &-rowHead {
//         border-radius: 1rem;
//       }

//       tbody {
//         padding: 1rem;
//         tr {
//           &:hover {
//             background-color: $pri15;
//             cursor: pointer;
//           }

//           td {
//             padding: 10px 15px;
//             border-bottom: 1px solid #eee;
//           }
//         }
//       }
//     }
//   }

//   .details-section {
//     h3 {
//       margin-top: 0;
//       color: #333;
//     }

//     p {
//       margin: 5px 0;
//       line-height: 1.6;

//       strong {
//         color: #555;
//       }
//     }

//     button {
//       padding: 10px 20px;
//       margin-right: 10px;
//       border: none;
//       border-radius: 4px;
//       background-color: #007bff;
//       color: white;
//       cursor: pointer;
//       transition: background-color 0.3s;

//       &:hover {
//         background-color: #0056b3;
//       }

//       &:last-child {
//         background-color: #dc3545;

//         &:hover {
//           background-color: #c82333;
//         }
//       }
//     }
//   }
// }

// @import "../../index.scss";

// .halfAdd {
//   & input[type="file"] {
//     width: 100%;
//     border: 1px solid $primary;
//     padding: 1.2rem;
//     border-radius: 1rem;
//     box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
//     margin: 1rem 0;
//     margin-bottom: 3rem;
//   }
// }
