@import "../../index.scss";
// Enhanced AssignedInventory.scss

.assigned-inventory {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .inventory-card {
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    padding: 16px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border-left: 3px solid #3498db;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .inventory-icon {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f0f7ff;
      border-radius: 8px;

      div {
        width: 24px;
        height: 24px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      .icon-laptop {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M20 18c1.1 0 1.99-.9 1.99-2L22 5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2H0c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2h-4zM4 5h16v11H4V5zm8 14c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z'/%3E%3C/svg%3E");
      }

      .icon-phone {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M17 1.01 7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14zm-4.2-5.78v1.75l2.81-2.62c.21-.2.21-.53 0-.73L12.8 9v1.7c-3.11.43-4.35 2.56-4.8 4.7 1.11-1.5 2.58-2.18 4.8-2.18z'/%3E%3C/svg%3E");
      }

      .icon-tablet {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M21 4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h18c1.1 0 1.99-.9 1.99-2L23 6c0-1.1-.9-2-2-2zm-2 14H5V6h14v12z'/%3E%3C/svg%3E");
      }

      .icon-printer {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z'/%3E%3C/svg%3E");
      }

      .icon-projector {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M22 14h-4v2h2v2h-2v2h4v-6zM5 14H2v6h4v-2H4v-2h2v-2zM8 2c-1.1 0-2 .9-2 2v10h12V4c0-1.1-.9-2-2-2H8zm4 7c-1.38 0-2.5-1.12-2.5-2.5S10.62 4 12 4s2.5 1.12 2.5 2.5S13.38 9 12 9z'/%3E%3C/svg%3E");
      }

      .icon-camera {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M9 3L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2h-3.17L15 3H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z'/%3E%3C/svg%3E");
      }

      .icon-headphones {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M12 1c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h3c1.66 0 3-1.34 3-3v-7c0-4.97-4.03-9-9-9z'/%3E%3C/svg%3E");
      }

      .icon-keyboard {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M20 5H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-9 3h2v2h-2V8zm0 3h2v2h-2v-2zM8 8h2v2H8V8zm0 3h2v2H8v-2zm-1 2H5v-2h2v2zm0-3H5V8h2v2zm9 7H8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2V8h2v2zm3 3h-2v-2h2v2zm0-3h-2V8h2v2z'/%3E%3C/svg%3E");
      }

      .icon-mouse {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M13 1.07V9h7c0-4.08-3.05-7.44-7-7.93zM4 15c0 4.42 3.58 8 8 8s8-3.58 8-8v-4H4v4zm7-13.93C7.05 1.56 4 4.92 4 9h7V1.07z'/%3E%3C/svg%3E");
      }

      .icon-monitor {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M21 2H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7v2H8v2h8v-2h-2v-2h7c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H3V4h18v12z'/%3E%3C/svg%3E");
      }

      .icon-device {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14zm-1-6h-3V8h-2v5H8l4 4 4-4z'/%3E%3C/svg%3E");
      }
    }

    .inventory-details {
      flex: 1;
      min-width: 0; // Prevents flex item from overflowing

      .inventory-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 8px;

        .inventory-name {
          font-size: 16px;
          font-weight: 600;
          color: #2c3e50;
          margin: 0;
          padding-right: 8px;
        }

        .time-assigned {
          font-size: 12px;
          background-color: #e8f4fd;
          color: #3498db;
          padding: 2px 8px;
          border-radius: 12px;
          white-space: nowrap;
        }
      }

      .inventory-serial {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-size: 13px;

        .serial-label {
          color: #7f8c8d;
          margin-right: 6px;
        }

        .serial-value {
          color: #2c3e50;
          font-family: monospace;
          background-color: #f8f9fa;
          padding: 2px 6px;
          border-radius: 4px;
        }
      }

      .inventory-meta {
        margin-bottom: 8px;

        .assign-date {
          display: flex;
          align-items: center;
          font-size: 13px;

          .date-label {
            color: #7f8c8d;
            margin-right: 6px;
          }

          .date-value {
            color: #34495e;
          }
        }
      }

      .inventory-remarks {
        background-color: #f8f9fa;
        border-left: 2px solid #e9ecef;
        padding: 8px 12px;
        border-radius: 0 4px 4px 0;
        margin-top: 8px;

        p {
          margin: 0;
          font-size: 13px;
          color: #5d6d7e;
          line-height: 1.5;
        }
      }
    }
  }
}

// Loading state styles
.inventory-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;

  .loading-spinner {
    width: 36px;
    height: 36px;
    border: 3px solid rgba(52, 152, 219, 0.2);
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 16px;
  }

  p {
    color: #7f8c8d;
    font-size: 14px;
    margin: 0;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

// Error state styles
.inventory-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;

  .error-icon {
    width: 48px;
    height: 48px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23e74c3c'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 16px;
  }

  p {
    color: #e74c3c;
    text-align: center;
    margin: 0 0 16px;
    font-size: 14px;
  }

  .retry-button {
    background-color: transparent;
    border: 1px solid #e74c3c;
    color: #e74c3c;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #e74c3c;
      color: white;
    }
  }
}

// Empty state styles
.inventory-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;

  .empty-icon {
    width: 48px;
    height: 48px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%237f8c8d'%3E%3Cpath d='M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 4h2v5l-1-.75L9 9V4zm9 16H6V4h1v9l3-2.25L13 13V4h5v16z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 16px;
    opacity: 0.5;
  }

  p {
    color: #7f8c8d;
    text-align: center;
    margin: 0;
    font-size: 14px;
  }
}

// Responsive styles
@media (max-width: 576px) {
  .assigned-inventory {
    .inventory-card {
      flex-direction: column;

      .inventory-icon {
        margin-right: 0;
        margin-bottom: 12px;
      }

      .inventory-header {
        flex-direction: column;
        align-items: flex-start;

        .time-assigned {
          margin-top: 4px;
        }
      }
    }
  }
}
