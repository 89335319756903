.profile-wrapper {
  width: 100%;
  padding: 20px;
  background-color: #f5f8fa;
  min-height: 100vh;
}

.profile-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.profile-container {
  max-width: 1000px;
  margin: 0 auto;
}

.profile-header {
  margin-bottom: 30px;

  h1 {
    font-size: 24px;
    margin: 0;
    font-weight: 500;
    color: #333;
  }
}

.profile-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto;
  grid-gap: 20px;

  .profile-image-box {
    grid-column: 1;
    grid-row: 1;
    background: white;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar-container {
      position: relative;
      width: 150px;
      height: 150px;
      margin-bottom: 20px;

      .profile-pic {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      .default-avatar {
        width: 100% !important;
        height: 100% !important;
      }

      .uploading-indicator {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .upload-actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      button {
        width: 100%;
      }
    }
  }

  .profile-info-box {
    grid-column: 2;
    grid-row: 1;
    background: white;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .info-row {
      display: flex;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #eee;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      .info-label {
        flex: 0 0 120px;
        font-weight: 500;
        color: #666;
      }

      .info-value {
        flex: 1;
        color: #333;
      }
    }
  }

  .profile-actions-box {
    grid-column: 1 / -1;
    grid-row: 2;
    background: white;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 15px;
  }
}

.confirmation-modal {
  padding: 20px;

  .modal-question {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }

  .modal-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
  }
}

// Responsive styles
@media (max-width: 768px) {
  .profile-grid {
    grid-template-columns: 1fr;

    .profile-image-box,
    .profile-info-box,
    .profile-actions-box {
      grid-column: 1;
    }

    .profile-image-box {
      grid-row: 1;
    }

    .profile-info-box {
      grid-row: 2;
    }

    .profile-actions-box {
      grid-row: 3;
      flex-direction: column;
    }
  }
}

@media (max-width: 480px) {
  .profile-wrapper {
    padding: 10px;
  }

  .profile-header h1 {
    font-size: 20px;
  }

  .info-row {
    flex-direction: column;

    .info-label {
      margin-bottom: 5px;
    }
  }

  .confirmation-modal .modal-buttons {
    flex-direction: column;
  }
}
