// Enhanced Topbar.scss
@import "../../index.scss";

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: linear-gradient(to right, #9092ff, #a0c6ff);
  background-color: $pri15;
  padding: 0 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 70px;

  &-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;

    img {
      border-radius: 50%;

      height: 48px;
      object-fit: contain;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    padding: 0 2rem;
    gap: 0.5rem;
  }

  &-item {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin: 0 0.25rem;
    font-weight: 500;
    color: #2c3e50;
    cursor: pointer;
    font-size: 1.4rem;
    border-radius: 6px;

    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;

    .menu-text {
      margin-right: 0.5rem;
    }

    .dropdown-indicator {
      display: inline-flex;
      align-items: center;
      margin-left: 0.25rem;

      .arrow {
        border: solid #2c3e50;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        transition: transform 0.2s ease-in-out;

        &.down {
          transform: rotate(45deg);
        }

        &.up {
          transform: rotate(-135deg);
        }
      }
    }

    &:hover {
      background-color: rgba(26, 115, 232, 0.08);
      color: #1a73e8;

      .arrow {
        border-color: #1a73e8;
      }
    }

    // Active state for menu items
    &.active {
      background-color: rgba(26, 115, 232, 0.12);
      color: #1a73e8;
      font-weight: 600;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 24px;
        height: 3px;
        background-color: #1a73e8;
        border-radius: 2px 2px 0 0;
      }

      .arrow {
        border-color: #1a73e8;
      }
    }

    // Expanded state for menu items
    &.expanded {
      background-color: #f1f5fd;

      .arrow {
        border-color: #1a73e8;
      }
    }

    .dropdown-menu {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      min-width: 220px;
      background-color: white;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      padding: 0.5rem 0;
      overflow: hidden;
      z-index: 1001;
      animation: fadeIn 0.2s ease-in-out;

      a {
        display: block;
        padding: 0.75rem 1.25rem;
        color: #2c3e50;
        text-decoration: none;
        transition: all 0.15s ease;
        font-size: 1.3rem;

        &:hover {
          background-color: #f1f5fd;
          color: #1a73e8;
        }

        // Active state for dropdown items
        &.active-dropdown {
          background-color: #e8f0fe;
          color: #1a73e8;
          font-weight: 500;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 3px;
            height: 18px;
            background-color: #1a73e8;
            border-radius: 0 2px 2px 0;
          }
        }
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &-user {
    position: relative;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #f8f9fa;
      transition: all 0.2s ease;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

      &:hover {
        transform: scale(1.05);
        border-color: #1a73e8;
      }
    }

    .user-status {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      background-color: #4caf50;
      border-radius: 50%;
      border: 2px solid white;
    }
  }

  .logout-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #e1e4e8;
    border-radius: 20px;
    padding: 0.4rem 1rem;
    color: #6c757d;
    font-size: 0.85rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;

    .logout-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236c757d'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'%3E%3C/path%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      transition: all 0.2s ease;
    }

    &:hover {
      background-color: #f8d7da;
      color: #dc3545;
      border-color: #f5c2c7;

      .logout-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23dc3545'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
}

// Animation for dropdown menu
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Media queries for responsive design
@media screen and (max-width: 992px) {
  .topbar {
    &-contents {
      padding: 0 1rem;
    }

    &-item {
      padding: 0.6rem 1rem;
      font-size: 0.9rem;
    }

    .logout-text {
      display: none;
    }

    .logout-button {
      padding: 0.4rem;
      border-radius: 50%;

      .logout-icon {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .topbar {
    padding: 0 1rem;

    &-contents {
      justify-content: flex-start;
      overflow-x: auto;
      padding: 0 0.5rem;

      &::-webkit-scrollbar {
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }

    &-item {
      margin: 0 0.15rem;
      white-space: nowrap;

      &.active:before {
        width: 16px;
      }
    }
  }
}
