@import "../../index.scss";

.cm {
  @include text;

  &-ques {
    margin-bottom: 2rem;
  }
  &-button {
    & > * {
      margin-left: 2rem;
    }
  }
}
