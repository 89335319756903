@import "../../index.scss";

// Variables
$primary-color: #4361ee;
$primary-light: rgba($primary-color, 0.1);
$primary-dark: darken($primary-color, 10%);

$success-color: #2ecc71;
$success-light: rgba($success-color, 0.1);
$success-dark: darken($success-color, 10%);

$danger-color: #e74c3c;
$danger-light: rgba($danger-color, 0.1);
$danger-dark: darken($danger-color, 10%);

$warning-color: #f39c12;
$warning-light: rgba($warning-color, 0.1);

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
$border-radius: 8px;
$transition: all 0.3s ease;

// Mixins
@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-start {
  display: flex;
  align-items: flex-start;
}

@mixin card {
  background-color: white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  transition: $transition;
}

// Main Component Styles
.student-fee {
  font-family: "Roboto", "Segoe UI", system-ui, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  color: $gray-800;

  // Error Message
  .error-message {
    background-color: $danger-light;
    color: $danger-color;
    padding: 16px;
    border-radius: $border-radius;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 500;

    svg {
      font-size: 20px;
    }
  }

  // Section Headers
  .section-header {
    @include flex-center;
    // display: flex;
    // align-items: center;
    gap: 12px;
    margin-bottom: 24px;

    h2 {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      color: $gray-800;
    }

    .section-icon {
      font-size: 24px;
      color: $primary-color;
    }
  }

  // Fee Summary Section
  .fee-summary {
    margin-bottom: 32px;

    .summary-cards {
      display: flex;
      justify-content: space-evenly;
      gap: 20px;
    }

    .summary-card {
      @include card;
      padding: 20px;
      display: flex;
      align-items: center;
      gap: 16px;
      width: 30%;

      &:hover {
        transform: translateY(-4px);
      }

      &.total-fee {
        border-left: 4px solid $primary-color;
        .card-icon {
          background-color: $primary-light;
          color: $primary-color;
        }
      }

      &.scholarshipcard {
        border-left: 4px solid $success-color;
        .card-icon {
          background-color: $success-light;
          color: $success-color;
        }
      }

      &.discount {
        border-left: 4px solid $warning-color;
        .card-icon {
          background-color: $warning-light;
          color: $warning-color;
        }
      }

      .card-icon {
        height: 56px;
        width: 56px;
        border-radius: 50%;
        @include flex-center;
        font-size: 24px;
      }

      .card-content {
        flex: 1;

        h3 {
          margin: 0 0 8px 0;
          font-size: 16px;
          font-weight: 500;
          color: $gray-600;
        }

        .amount {
          display: block;
          font-size: 24px;
          font-weight: 700;
          color: $gray-800;
        }
      }
    }
  }

  // Installments Section
  .installments-section {
    .installments-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .installment-card {
      @include card;
      border: 1px solid $gray-200;
      overflow: hidden;

      .installment-header {
        @include flex-between;
        padding: 16px 20px;
        cursor: pointer;

        &.paid {
          background-color: $success-light;
        }

        &.unpaid {
          background-color: $danger-light;
        }

        &:hover {
          background-color: $primary-light;
        }

        .installment-info {
          @include flex-center;
          gap: 16px;
        }

        .installment-name {
          font-size: 16px;
          font-weight: 600;
        }

        .status-badge {
          display: inline-flex;
          align-items: center;
          gap: 6px;
          font-size: 14px;
          font-weight: 500;
          padding: 6px 12px;
          border-radius: 50px;

          &.paid {
            background-color: $success-light;
            color: $success-color;
          }

          &.unpaid {
            background-color: $danger-light;
            color: $danger-color;
          }
        }

        .toggle-icon {
          font-size: 18px;
          color: $gray-600;
        }
      }

      .installment-details {
        padding: 20px;
        border-top: 1px solid $gray-200;
        background-color: white;

        .details-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 20px;
          margin-bottom: 24px;
        }

        .detail-item {
          @include flex-start;
          gap: 12px;

          &.final-fee {
            grid-column: 1 / -1;
            margin-top: 16px;
            padding-top: 16px;
            border-top: 1px dashed $gray-300;

            .detail-label,
            .detail-value {
              font-weight: 700;
              color: $gray-900;
            }
          }

          .detail-icon {
            min-width: 24px;
            font-size: 18px;
            color: $primary-color;
          }

          .detail-content {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .detail-label {
            font-size: 14px;
            color: $gray-600;
          }

          .detail-value {
            font-size: 16px;
            font-weight: 500;

            &.late-fee {
              color: $danger-color;
              font-weight: 600;
            }
          }
        }

        .installment-actions {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          gap: 12px;
        }

        .payment-link-info {
          margin-left: auto;
          padding: 16px;
          background-color: $gray-100;
          border-radius: $border-radius;
          border-left: 3px solid $primary-color;

          .link-status {
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: 600;
            color: $gray-800;
            margin-bottom: 8px;
          }

          .link-id {
            font-family: monospace;
            background-color: $gray-200;
            padding: 6px 10px;
            border-radius: 4px;
            font-size: 14px;
            margin-bottom: 12px;
          }
        }

        .admin-actions {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
        }
      }
    }
  }

  // Button Styles
  .action-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    border-radius: $border-radius;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s ease;

    &:active {
      transform: scale(0.98);
    }

    &.btn-primary {
      background-color: $primary-color;
      color: white;

      &:hover {
        background-color: $primary-dark;
      }
    }

    &.btn-success {
      background-color: $success-color;
      color: white;

      &:hover {
        background-color: $success-dark;
      }
    }

    &.btn-danger {
      background-color: $danger-color;
      color: white;

      &:hover {
        background-color: $danger-dark;
      }
    }
  }

  // Modal Styles
  .modal-header {
    @include flex-between;
    padding: 16px 20px;
    border-bottom: 1px solid $gray-200;

    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .modal-actions {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .close-btn {
      background: none;
      border: none;
      font-size: 20px;
      color: $gray-600;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      border-radius: 50%;
      transition: background-color 0.2s;

      &:hover {
        background-color: $gray-200;
        color: $danger-color;
      }
    }

    .download-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: $primary-dark;
      }
    }
  }

  .receipt-modal {
    .receipt-content {
      padding: 20px;
      max-height: 70vh;
      overflow-y: auto;
    }
  }

  .link-modal {
    max-width: 600px;
  }

  .payment-modal {
    max-width: 500px;
  }
}

// Media Queries for Responsive Design
@media (max-width: 768px) {
  .student-fee {
    padding: 16px;

    .fee-summary {
      .summary-cards {
        grid-template-columns: 1fr;
      }
    }

    .installment-details {
      .details-grid {
        grid-template-columns: 1fr;
      }

      .installment-actions,
      .admin-actions {
        flex-direction: column;
        width: 100%;

        .action-btn {
          width: 100%;
        }
      }
    }

    .modal-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      .modal-actions {
        width: 100%;

        .download-btn {
          flex: 1;
        }
      }
    }
  }
}

/////////////////////////////////////////
/// Receipt Styles
///
/// General Styles for receipt.

.receipt-modal {
  button {
    margin-bottom: 5px;
  }
}
.receipt-cis,
.receipt-kcepl,
.receipt-ccf {
  font-family: Arial, sans-serif;
  color: #333;
  width: 1100px;
  margin: 0 auto;
  // border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: left;

  .receipt-section {
    margin: 20px 0;

    &-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .receipt-table {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;

    thead {
      tr {
        padding: 0 5px;
        background-color: $pri15;
        border-bottom: 3px solid black;

        th {
          border: 1px solid rgb(79, 79, 79);
          padding: 0 5px;
        }
      }
    }

    tbody {
      text-align: left;
      width: 100%;

      tr {
        width: 100%;

        .th25 {
          padding: 0 5px;
          width: 25%;
          border: 1px solid rgb(79, 79, 79);
          background-color: $pri15;
        }
        .td25 {
          padding: 0 5px;
          width: 25%;
          border: 1px solid rgb(79, 79, 79);
        }

        .th50 {
          padding: 0 5px;
          width: 50%;
          border: 1px solid rgb(79, 79, 79);
          background-color: $pri15;
        }
        .td50 {
          padding: 0 5px;
          width: 50%;
          border: 1px solid rgb(79, 79, 79);
        }
      }
    }
  }
}

/// Receipt header styles
.receipt-header {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: $primary;

  &-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    img {
      max-width: 80px;
      border-radius: 50%;
    }
  }
  &-text {
    text-align: right;
    &-name {
      font-size: 30px;
      font-weight: 600;
      color: white;
    }
    &-add {
      font-size: 20px;
      font-weight: 500;
      color: rgb(229, 229, 229);
    }
  }
}

/// Receipt Info Styles
.receipt-ccf {
  text-align: left;
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;

    th,
    td {
      padding: 10px;
      text-align: left;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
      font-weight: bold;
      color: #333;
    }
  }
}

// Payment Breakdown Styles
.components-info,
.receipt-kcepl .components-info {
  margin: 20px 0;

  h4,
  h3 {
    font-size: 16px;
    color: #444;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;

    th,
    td {
      padding: 10px;
      text-align: left;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
      font-weight: bold;
      color: #333;
    }
  }
}

.receipt-compInfo {
  font-size: 16px;
  margin: 20px 0;
}

// Footer Styles
.receipt-footer {
  text-align: center;
  margin-top: 20px;

  p {
    margin: 5px 0;
    font-size: 12px;
    color: #666;
  }
}

.accept-payment {
  padding: 20px;
  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 400px;
  margin: 20px auto;

  h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
  }
  & .info {
    color: $grey1;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  .form-group {
    width: 100%;
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 5px;
      color: #666;
      font-size: 16px;
    }

    select,
    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      display: block;

      &:focus {
        outline: none;
        border-color: #0056b3;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      background-color: #0056b3;
      color: white;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        background-color: #004494;
      }

      &:first-child {
        background-color: #ccc;
        color: #333;

        &:hover {
          background-color: #bbb;
        }
      }
    }
  }
}
