// Variables
$primary-color: #4361ee;
$primary-dark: #3a56d4;
$primary-light: #eef1ff;
$secondary-color: #2d3748;
$success-color: #10b981;
$warning-color: #f59e0b;
$danger-color: #ef4444;
$text-primary: #1e293b;
$text-secondary: #64748b;
$text-muted: #94a3b8;
$border-color: #e2e8f0;
$background-light: #f8fafc;
$background-lighter: #ffffff;
$border-radius: 8px;
$box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
$transition-default: all 0.2s ease-in-out;

// Mixins
@mixin card {
  background-color: $background-lighter;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 24px;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin button-base {
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  transition: $transition-default;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: none;
  outline: none;
  white-space: nowrap;
  line-height: 1.5;

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  i {
    margin-right: 8px;
  }
}

// Main Styles
.student-details-page {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  max-width: 90%;
  margin: 0 auto;
  padding: 24px;
  color: $text-primary;

  // Page Header
  .page-header {
    @include flex-between;
    margin-bottom: 24px;

    .breadcrumb {
      font-size: 14px;
      color: $text-secondary;

      span {
        cursor: pointer;

        &:hover {
          color: $primary-color;
        }
      }
    }

    .header-actions {
      display: flex;
      gap: 12px;
    }
  }

  // Status Banners
  .status-banner {
    @include card;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-left: 4px solid;
    padding: 16px 24px;

    i {
      font-size: 24px;
    }

    &.warning {
      background-color: rgba($warning-color, 0.1);
      border-left-color: $warning-color;

      i {
        color: $warning-color;
      }
    }

    &.danger {
      background-color: rgba($danger-color, 0.1);
      border-left-color: $danger-color;

      i {
        color: $danger-color;
      }

      strong {
        font-size: 16px;
        display: block;
        margin-bottom: 4px;
      }

      p {
        margin: 2px 0;
        color: $text-secondary;
      }
    }
  }

  // Profile Card
  .profile-card {
    @include card;
    margin-bottom: 24px;

    .profile-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .profile-photo {
        position: relative;
        width: 160px;
        height: 160px;
        overflow: hidden;
        border-radius: 12px;
        box-shadow: $box-shadow;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }

        .photo-actions {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: space-around;
          background: rgba(0, 0, 0, 0.7);
          padding: 8px 0;

          button {
            background: none;
            border: none;
            color: white;
            font-size: 12px;
            cursor: pointer;
            padding: 4px 8px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            gap: 4px;
            transition: $transition-default;

            &:hover {
              background: rgba(255, 255, 255, 0.2);
            }

            i {
              font-size: 14px;
            }
          }
        }
      }

      .profile-info {
        flex: 1;
        min-width: 60%;
        // display: flex;
        // flex-direction: column;

        h2 {
          margin: 0 0 16px 0;
          font-size: 24px;
          font-weight: 600;
        }

        .info {
          width: 100%;
          display: flex;

          .key-details {
            width: 48%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .detail-item {
              display: flex;
              align-items: center;
              gap: 12px;
              color: $text-secondary;
              font-size: 14px;

              i {
                font-size: 18px;
                color: $primary-color;
                width: 20px;
                text-align: center;
              }

              strong {
                color: $text-primary;
                font-weight: 600;
              }
            }
          }
        }
      }

      .profile-actions {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;

        @media (max-width: 768px) {
          align-self: auto;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
  }

  // Content Tabs
  .content-tabs {
    margin-bottom: 30px;

    .tabs-nav {
      display: flex;
      border-bottom: 1px solid $border-color;
      margin-bottom: 24px;
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .tab-item {
        padding: 12px 20px;
        font-weight: 500;
        color: $text-secondary;
        cursor: pointer;
        white-space: nowrap;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 2px;
          background-color: transparent;
          transition: $transition-default;
        }

        &.active {
          color: $primary-color;

          &:after {
            background-color: $primary-color;
          }
        }

        &:hover:not(.active) {
          color: $primary-dark;
        }
      }
    }

    .tab-content {
      min-height: 200px;
    }
  }

  // Information Sections
  .detail-sections {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .info-section {
    @include card;

    .info-section-header {
      @include flex-between;
      margin-bottom: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid $border-color;

      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }

      button {
        @include button-base;
        color: $primary-color;
        background-color: transparent;
        border: 1px solid $primary-color;

        &:hover {
          background-color: $primary-light;
        }
      }
    }

    .info-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;

      .info-field {
        &.fullWidth {
          grid-column: 1 / -1;
        }

        .field-label {
          font-size: 13px;
          color: $text-secondary;
          margin-bottom: 8px;
          display: block;
        }

        .field-value {
          font-size: 15px;
          color: $text-primary;
          font-weight: 500;

          &.empty {
            color: $text-muted;
            font-style: italic;
          }
        }

        .field-input {
          width: 100%;
          padding: 10px 12px;
          border: 1px solid $border-color;
          border-radius: 6px;
          font-size: 14px;
          transition: $transition-default;

          &:focus {
            border-color: $primary-color;
            outline: none;
            box-shadow: 0 0 0 3px rgba($primary-color, 0.2);
          }

          &.error {
            border-color: $danger-color;

            &:focus {
              box-shadow: 0 0 0 3px rgba($danger-color, 0.2);
            }
          }
        }

        select.field-input {
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%2364748b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 12px center;
          padding-right: 40px;
        }

        .error-message {
          color: $danger-color;
          font-size: 12px;
          margin-top: 4px;
        }
      }
    }
  }

  // Services Container
  .services-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .service-section {
      padding: 16px;
      background-color: rgba($primary-light, 0.5);
      border-radius: $border-radius;

      h4 {
        margin-top: 0;
        margin-bottom: 16px;
        font-size: 16px;
        color: $primary-dark;
        border-bottom: 1px solid rgba($primary-color, 0.2);
        padding-bottom: 8px;
      }
    }
  }

  // Bed Details Card
  .bed-details-card {
    margin-top: 16px;
    padding: 16px;
    background-color: $primary-light;
    border-radius: $border-radius;

    h4 {
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 16px;
      color: $primary-dark;
    }

    .bed-info-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .bed-info-item {
        display: flex;
        flex-direction: column;

        .bed-info-label {
          font-size: 12px;
          color: $text-secondary;
        }

        .bed-info-value {
          font-size: 16px;
          font-weight: 600;
          color: $primary-dark;
        }
      }
    }

    button {
      margin-top: 16px;
    }
  }

  // Fees Container
  .fees-container {
    @include card;

    .fees-header {
      @include flex-between;
      margin-bottom: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid $border-color;

      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  // Hostel Management
  .bed-assignment-container {
    @include card;

    h3 {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
    }

    .available-beds-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      gap: 20px;

      .bed-card {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        overflow: hidden;
        transition: $transition-default;

        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }

        .bed-card-header {
          background-color: $primary-color;
          color: white;
          padding: 12px 16px;

          h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .bed-card-details {
          padding: 16px;

          .bed-detail-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            font-size: 14px;

            .bed-detail-label {
              color: $text-secondary;
            }

            .bed-detail-value {
              font-weight: 600;
            }
          }
        }

        .bed-card-actions {
          padding: 16px;
          border-top: 1px solid $border-color;
          text-align: center;
        }
      }
    }
  }

  .assigned-bed-container {
    @include card;

    .assigned-bed-card {
      background-color: $primary-light;
      border-radius: $border-radius;
      padding: 20px;

      .assigned-bed-header {
        margin-bottom: 16px;

        h3 {
          margin: 0;
          font-size: 18px;
          font-weight: 600;
          color: $primary-dark;
        }
      }

      .assigned-bed-details {
        margin-bottom: 16px;

        .assigned-bed-row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
          margin-bottom: 16px;

          @media (max-width: 576px) {
            grid-template-columns: 1fr;
            gap: 12px;
          }

          .assigned-bed-detail {
            background-color: white;
            padding: 12px;
            border-radius: $border-radius;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

            .detail-label {
              font-size: 12px;
              color: $text-secondary;
              display: block;
              margin-bottom: 4px;
            }

            .detail-value {
              font-size: 16px;
              font-weight: 600;
              color: $text-primary;
            }
          }
        }
      }

      .assigned-bed-actions {
        text-align: right;
      }
    }
  }

  // Messages Container
  .messages-container {
    @include card;

    .messages-header {
      @include flex-between;
      margin-bottom: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid $border-color;

      h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .messages-list {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .message-card {
        padding: 16px;
        border-radius: $border-radius;
        background-color: $background-light;
        border-left: 4px solid $primary-color;

        .message-header {
          @include flex-between;
          margin-bottom: 12px;

          .message-sender {
            font-weight: 600;
          }

          .message-time {
            font-size: 12px;
            color: $text-secondary;
          }
        }

        .message-content {
          line-height: 1.5;
          margin-bottom: 12px;
        }

        .message-whatsapp-badge {
          display: inline-flex;
          align-items: center;
          gap: 6px;
          font-size: 12px;
          padding: 4px 8px;
          border-radius: 12px;
          background-color: #25d366;
          color: white;

          i {
            font-size: 14px;
          }
        }
      }
    }
  }

  // Empty States
  .empty-state {
    @include flex-center;
    flex-direction: column;
    min-height: 200px;
    text-align: center;
    color: $text-secondary;

    i {
      font-size: 48px;
      color: $text-muted;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      margin: 0;
    }
  }

  // Error Banner
  .error-banner {
    background-color: rgba($danger-color, 0.1);
    border-left: 4px solid $danger-color;
    padding: 16px;
    margin-top: 20px;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    gap: 12px;

    i {
      color: $danger-color;
      font-size: 24px;
    }
  }

  // Access Denied
  .permission-denied {
    @include flex-center;
    flex-direction: column;
    min-height: 300px;
    text-align: center;

    i {
      font-size: 64px;
      color: $text-muted;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 24px;
      margin: 0 0 16px 0;
    }

    p {
      color: $text-secondary;
      max-width: 400px;
    }
  }

  // Button Styles
  button {
    @include button-base;

    &.btn-primary {
      background-color: $primary-color;
      color: white;

      &:hover {
        background-color: $primary-dark;
      }
    }

    &.btn-secondary {
      background-color: $secondary-color;
      color: white;

      &:hover {
        background-color: darken($secondary-color, 10%);
      }
    }

    &.btn-success {
      background-color: $success-color;
      color: white;

      &:hover {
        background-color: darken($success-color, 10%);
      }
    }

    &.btn-danger {
      background-color: $danger-color;
      color: white;

      &:hover {
        background-color: darken($danger-color, 10%);
      }
    }

    &.btn-primary-outline {
      background-color: transparent;
      border: 1px solid $primary-color;
      color: $primary-color;

      &:hover {
        background-color: $primary-light;
      }
    }

    &.btn-danger-outline {
      background-color: transparent;
      border: 1px solid $danger-color;
      color: $danger-color;

      &:hover {
        background-color: rgba($danger-color, 0.1);
      }
    }
  }

  // Photo upload modal
  .photo-upload-container {
    width: 100%;
    max-width: 400px;

    h3 {
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
    }

    .photo-preview {
      width: 160px;
      height: 160px;
      margin: 0 auto 16px;
      border-radius: 8px;
      overflow: hidden;
      background-color: $background-light;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    input[type="file"] {
      display: block;
      width: 100%;
      padding: 8px;
      margin-bottom: 16px;
      border: 1px dashed $border-color;
      border-radius: $border-radius;
    }

    .modal-actions {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }

  // Message Sender Component
  .message-sender {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 16px;

    .message-input {
      flex: 1;
      padding: 10px 16px;
      border: 1px solid $border-color;
      border-radius: 20px;
      font-size: 14px;

      &:focus {
        outline: none;
        border-color: $primary-color;
        box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
      }
    }

    .whatsapp-checkbox {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-size: 14px;
      gap: 6px;
      color: $text-secondary;

      input {
        margin: 0;
      }
    }

    .send-button {
      @include button-base;
      background-color: $primary-color;
      color: white;
      border-radius: 20px;

      &:hover {
        background-color: $primary-dark;
      }
    }
  }
}
