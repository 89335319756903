@import "../../index.scss";

// Loading animation
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;

  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba($primary, 0.2);
    border-radius: 50%;
    border-top-color: $primary;
    animation: spinner 1s linear infinite;
    margin-bottom: 1rem;
  }

  p {
    color: $grey1;
    font-size: 1.6rem;
    font-weight: 500;
  }
}

// Main dashboard container
.stfdash {
  padding: 2rem;
  background-color: #eaf2fa;
  min-height: 100vh;

  @include respond(phone) {
    padding: 1rem;
  }

  // Header section
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    @include respond(phone) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    &-welcome {
      text-align: left;
      h2 {
        font-size: 2.4rem;
        color: $grey1;
        font-weight: 600;
        margin: 0;
      }

      .current-date {
        color: $grey2;
        font-size: 1.4rem;
        margin-top: 0.5rem;
      }
    }

    &-quick-actions {
      display: flex;
      gap: 1rem;

      .action-button {
        display: flex;
        align-items: center;
        background-color: white;
        border: none;
        padding: 0.8rem 1.5rem;
        border-radius: 8px;
        font-size: 1.4rem;
        color: $grey1;
        font-weight: 500;
        cursor: pointer;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
        transition: all 0.2s ease;

        &:hover {
          background-color: $primary;
          color: white;
          transform: translateY(-2px);
          box-shadow: 0 4px 10px rgba($primary, 0.3);
        }

        .action-icon {
          margin-right: 0.8rem;
          font-size: 1.6rem;
        }
      }
    }
  }

  // Tab navigation
  &-tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 0.5rem;

    .tab-button {
      background: none;
      border: none;
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
      color: $grey2;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        width: 0;
        height: 3px;
        background-color: $primary;
        transition: width 0.3s ease;
      }

      &:hover {
        color: darken($grey4, 100%);
      }

      &.active {
        color: $primary;
        font-weight: 600;

        &::after {
          width: 100%;
        }
      }
    }
  }

  // Main content container
  &-contents {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    // Staff info section
    &-info {
      background-color: $primary;
      border-radius: 12px;
      padding: 2rem;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        @include respond(tablet) {
          flex-direction: column;
          gap: 1.5rem;
          align-items: flex-start;
        }
      }

      &-brand {
        display: flex;
        align-items: center;

        img {
          height: 4.5rem;
          width: auto;
          margin-right: 1.5rem;
        }

        div {
          font-size: 2rem;
          font-weight: 600;
          color: white;
        }
      }

      &-name {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include respond(tablet) {
          align-items: flex-start;
        }

        h3 {
          font-size: 2.2rem;
          font-weight: 600;
          color: $grey1;
          margin: 0 0 0.5rem 0;
        }

        .staff-designation {
          font-size: 1.5rem;
          color: $grey3;
          background-color: rgba($primary, 0.1);
          padding: 0.4rem 1.2rem;
          border-radius: 1.5rem;
          display: inline-block;
        }
      }

      &-container {
        display: flex;
        gap: 3rem;

        @include respond(tablet) {
          flex-direction: column;
          gap: 2rem;
        }
      }

      &-profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 0 25%;

        .profile-photo {
          width: 14rem;
          height: 14rem;
          border-radius: 50%;
          object-fit: cover;
          border: 4px solid white;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          margin-bottom: 1.5rem;
        }

        .profile-info {
          width: 100%;

          .info-item {
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }

            .info-label {
              font-size: 1.3rem;
              color: $grey3;
              margin-bottom: 0.3rem;
            }

            .info-value {
              font-size: 1.5rem;
              font-weight: 500;
              color: $grey1;
            }
          }
        }

        @include respond(tablet) {
          flex-direction: row;
          gap: 2rem;
          align-items: center;

          .profile-photo {
            margin-bottom: 0;
            width: 10rem;
            height: 10rem;
          }
        }

        @include respond(phone) {
          flex-direction: column;
          align-items: center;

          .profile-photo {
            margin-bottom: 1.5rem;
          }

          .profile-info {
            text-align: center;
          }
        }
      }

      &-details {
        flex: 1;

        .info-row {
          display: flex;
          margin-bottom: 2rem;
          gap: 3rem;

          &:last-child {
            margin-bottom: 0;
          }

          @include respond(phone) {
            flex-direction: column;
            gap: 1.5rem;
          }

          .info-item {
            flex: 1;
            background-color: #f8f9fa;
            padding: 1.5rem;
            border-radius: 8px;

            .info-label {
              font-size: 1.3rem;
              color: $grey3;
              margin-bottom: 0.5rem;
            }

            .info-value {
              font-size: 1.6rem;
              font-weight: 500;
              color: $grey1;
            }
          }
        }
      }
    }

    // Grid layout for dashboard widgets
    &-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto auto;
      gap: 2rem;

      @include respond(tablet) {
        grid-template-columns: 1fr;
      }

      // Schedule section spans two columns
      .stfdash-contents-schedule {
        grid-column: span 2;

        @include respond(tablet) {
          grid-column: span 1;
        }
      }
    }

    // Announcements widget
    &-ancmnts,
    &-exam,
    &-schedule {
      background-color: white;
      border-radius: 12px;
      padding: 2rem;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        h3 {
          font-size: 1.8rem;
          font-weight: 600;
          color: $grey1;
          margin: 0;
        }

        .view-all-button {
          background: none;
          border: none;
          font-size: 1.4rem;
          color: $primary;
          cursor: pointer;
          transition: color 0.2s ease;

          &:hover {
            color: darken($primary, 15%);
            text-decoration: underline;
          }
        }
      }

      &-content {
        max-height: 350px;
        overflow-y: auto;
        padding-right: 0.5rem;

        // Custom scrollbar
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba($primary, 0.5);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: $primary;
        }
      }
    }

    // Schedule specific styles
    &-schedule {
      &-content {
        .schedule-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .schedule-item {
            display: flex;
            padding: 1.2rem;
            background-color: #f8f9fa;
            border-radius: 8px;
            border-left: 4px solid $primary;

            .schedule-time {
              font-size: 1.4rem;
              font-weight: 600;
              color: $primary;
              padding-right: 1.5rem;
              border-right: 1px solid rgba(0, 0, 0, 0.1);
              margin-right: 1.5rem;
              min-width: 7rem;
              display: flex;
              align-items: center;
            }

            .schedule-details {
              .schedule-class {
                font-size: 1.6rem;
                font-weight: 500;
                color: $grey1;
                margin-bottom: 0.3rem;
              }

              .schedule-subject {
                font-size: 1.4rem;
                color: $grey3;
              }
            }
          }
        }

        .no-schedule {
          padding: 3rem 0;
          text-align: center;
          color: $grey3;
          font-size: 1.6rem;
          background-color: rgba(0, 0, 0, 0.02);
          border-radius: 8px;
        }
      }
    }
  }

  // Tab content area
  &-tab-content {
    background-color: white;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

    h2 {
      font-size: 2rem;
      font-weight: 600;
      color: $grey1;
      margin: 0 0 2rem 0;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
}
