// Enhanced HalfDetails.scss
@import "../../index.scss";

.half-details {
  display: flex;
  flex-direction: column;
  height: 70vh;
  background-color: #f5fafe;

  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;

  // Animation on mount
  &.animate-in {
    opacity: 0;
    transform: translateY(10px);
  }

  // Header section
  .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16px 20px;
    background-color: $primary;

    .title-container {
      flex: 1;
      min-width: 0; // Prevents flex item from overflowing

      .details-title {
        font-size: 20px;
        font-weight: 700;
        color: #ececec;
        margin: 0 0 4px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }

      // .details-id {
      //   font-size: 12px;
      //   color: #94a3b8;
      // }
    }

    .action-buttons {
      display: flex;
      align-items: center;
      gap: 12px;

      .delete-button {
        position: relative;
        background: none;
        border: none;
        cursor: pointer;
        padding: 6px;
        border-radius: 4px;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #fee2e2;

          .button-tooltip {
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
          }
        }

        img {
          width: 20px;
          height: 20px;
          opacity: 0.7;
          transition: opacity 0.2s ease;
        }

        &:hover img {
          opacity: 1;
        }

        .button-tooltip {
          position: absolute;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%) translateY(5px);
          background-color: #1e293b;
          color: white;
          padding: 5px 8px;
          border-radius: 4px;
          font-size: 12px;
          white-space: nowrap;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.2s ease, transform 0.2s ease,
            visibility 0.2s ease;
          z-index: 10;

          &:before {
            content: "";
            position: absolute;
            top: -4px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            width: 8px;
            height: 8px;
            background-color: #1e293b;
          }
        }
      }
    }
  }

  // Divider
  .details-divider {
    height: 1px;
    background-color: #e2e8f0;
    margin: 0;
  }

  // Content section
  .details-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;

    // Styling for potential scrollbar
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f5f9;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cbd5e1;
      border-radius: 4px;

      &:hover {
        background: #94a3b8;
      }
    }

    // Default styling for common child elements
    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #334155;
      margin: 0 0 12px 0;
    }

    p {
      margin: 0 0 16px 0;
      color: #475569;
      line-height: 1.6;
    }

    label {
      font-size: 13px;
      font-weight: 500;
      color: #64748b;
      display: block;
      margin-bottom: 4px;
    }
  }

  // Footer section
  .details-footer {
    padding: 16px 20px;
    border-top: 1px solid #e2e8f0;
    background-color: #f8fafc;
    display: flex;
    justify-content: flex-end;

    button {
      background-color: #3498db;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #2980b9;
      }
    }
  }
}

// DetailsChild component common styling patterns
// These styles will apply to commonly used patterns in the children components

.detail-section {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  .section-title {
    font-size: 14px;
    font-weight: 600;
    color: #64748b;
    margin: 0 0 12px 0;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 16px;
      background-color: #3498db;
      margin-right: 8px;
      border-radius: 2px;
    }
  }

  .field-row {
    display: flex;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .field-label {
      flex: 0 0 120px;
      font-size: 13px;
      color: #64748b;
    }

    .field-value {
      flex: 1;
      font-size: 14px;
      color: #334155;
      font-weight: 500;
    }
  }

  .text-content {
    background-color: #f8fafc;
    padding: 12px 16px;
    border-radius: 6px;
    color: #475569;
    font-size: 14px;
    line-height: 1.6;
    margin-top: 8px;
  }
}

// Responsive adjustments
@media screen and (max-width: 576px) {
  .half-details {
    .details-header {
      flex-direction: column;
      align-items: flex-start;

      .title-container {
        margin-bottom: 12px;
      }
    }

    .details-content {
      padding: 16px;
    }

    .detail-section {
      .field-row {
        flex-direction: column;

        .field-label {
          margin-bottom: 4px;
        }
      }
    }
  }
}

// @import "../../index.scss";

// .hdetails {
//   width: 100%;
//   border-radius: 2rem;
//   // background-color: #4585e5ad;
//   background-color: $secondary;
//   max-height: 60vh;
//   overflow-y: scroll;

//   &-header {
//     padding: 1rem 2rem;

//     color: white;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     border-radius: 2rem;

//     &-name {
//       font-size: 2rem;
//       font-weight: 700;
//       background-color: white;
//       color: $primary;
//       padding: 0.75rem 2rem;
//       border-radius: 1rem;
//       min-width: 50%;
//       text-align: left;
//     }

//     & img {
//       height: 5rem;
//       width: auto;
//       cursor: pointer;
//     }
//   }

//   &-contents {
//     background-color: white;
//     padding: 2rem;
//     margin: 0 2rem;
//     border-radius: 2rem;
//   }

//   &-footer {
//     padding: 2rem;
//     margin-bottom: 2rem;
//     color: white;
//   }
// }
